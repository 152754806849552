import TelegramIcon from '../assets/images/TelegramIcon.png';
import Tiktok from '../assets/images/tiktok.svg';
import medium from '../assets/images/medium.svg';
import reddit from '../assets/images/reddit.svg';
import XIcon from '../assets/images/XIcon.png';
import WebsiteIcon from '../assets/images/WebsiteIcon.svg';
import AlephiumIcon from '../assets/images/AlephiumIcon.svg';
import DiscordIcon from '../assets/images/DiscordIcon.svg';
import Youtube from '../assets/images/Youtube.svg';
import mastodon from '../assets/images/mastodon.svg';
import ByBitIcon from '../assets/images/ByBitIcon.png';


export function formatString(str) {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function formatNumber(num) {
  return num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}



export function TaskMissionIcon(icon) {
  const data = [
    {
      name: 'telegram',
      icon: TelegramIcon,
    },
    {
      name: 'x',
      icon: XIcon,
    },
    {
      name: 'web',
      icon: WebsiteIcon,
    },
    {
      name: 'app',
      icon: AlephiumIcon,
    },
    {
      name: 'tiktok',
      icon: Tiktok,
    },
    {
      name: 'medium',
      icon: medium,
    },
    {
      name: 'reddit',
      icon: reddit,
    },
    {
      name: 'discord',
      icon: DiscordIcon,
    },
    {
      name: 'youtube',
      icon: Youtube,
    },
    {
      name: 'mastodon',
      icon: mastodon,
    },
    {
      name: 'bybit',
      icon: ByBitIcon,
    },
  ];

  const Leagueicon = data.find(item => item?.name === icon);

  return Leagueicon?.icon;
}

export function ButtonName(status) {
  const data = [
    {
      status: 'created',
      name: 'Go',
    },
    {
      status: 'unclaimed',
      name: 'Check',
    },
    {
      status: 'in_progress',
      name: '',
    },
  ];

  const Leagueicon = data.find(item => item?.status === status);

  return Leagueicon;
}


export function parseWalletLinks(inputString) {
  // Split the input string by '||' to separate different URLs
  const urls = inputString.split('||').map(url => url.trim());

  // Initialize an array to store the output
  const result = [];

  // Iterate over the URLs to determine their platform and create the result objects
  urls.forEach(url => {
    let title = '';
    if (url.includes('play.google.com')) {
      title = 'android';
    } else if (url.includes('apps.apple.com')) {
      title = 'ios';
    } else {
      title = 'tdesktop';
    }

    // Push the object to the result array
    result.push({ title, link: url });
  });

  return result;
}


export const sortDataByStatusAndId = (data) => {
  const statusOrder = { 'unclaimed': 1, 'created': 2, 'claimed': 3 };
  
  return data?.sort((a, b) => {
    if (statusOrder[a?.status] !== statusOrder[b?.status]) {
      return statusOrder[a?.status] - statusOrder[b?.status];
    }
    return b?.id - a?.id; // Sorting by id in descending order
  });
};